
































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import TRUCKS from '@/graphql/queries/Trucks.graphql';
import TRUCK_DELETE from '@/graphql/mutations/TruckDelete.graphql';
import { Truck } from '@/types/schema';

export default Vue.extend({
  name: 'AdminTrucks',
  components: {
    AppMain,
    AppEmpty,
    AppPanel,
    AppPanelItem,
  },
  data() {
    return {
      trucks: [] as Truck[],
    };
  },
  methods: {
    async deleteTruck(id: Truck['id']) {
      if (!confirm("Are you sure? This action can't be undone.")) return;

      try {
        await this.$apollo.mutate({
          mutation: TRUCK_DELETE,
          variables: {
            input: { id },
          },
        });
        this.$apollo.queries.trucks.refetch();
        this.$notify({
          text: 'Truck deleted!',
          type: 'success',
          duration: 6000,
        });
      } catch (error) {
        this.$notify({
          text: error.graphQLErrors[0].message,
          type: 'error',
          duration: 6000,
        });
      }
    },
  },
  apollo: {
    trucks: {
      query: TRUCKS,
      fetchPolicy: 'cache-and-network',
    },
  },
});
