

















import Vue from 'vue';
import AppIcon from '@/components/AppIcon.vue';

export default Vue.extend({
  name: 'AppEmpty',
  components: {
    AppIcon,
  },
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
});
